import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { useEffect } from 'react';
import "./App.css";
import { SidebarProvider } from "./ContextApi/SidebarContext";
import AdminRoute from "./RouterFile/AdminRoute";
import ClientRouter from "./RouterFile/ClientRouter";
import AdminLogin from "./pages/admin/AdminLogin";

function App() {

  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = `https://www.googletagmanager.com/gtag/js?id=G-NSFMPFHWW0`;
  //   script.async = true;
  //   document.head.appendChild(script);
  //   window.dataLayer = window.dataLayer || [];
  //   function gtag() { window.dataLayer.push(arguments); }
  //   gtag('js', new Date());
  //   gtag('config', 'G-NSFMPFHWW0');
  // }, []);



  return (
    <SidebarProvider>
      <Router>
        <Routes>
        <Route path="/*" element={<ClientRouter />} />
          <Route path="/admin/*" element={<AdminRoute />} />
          <Route path="/admin/loginadmin" element={<AdminLogin />} />

        </Routes>
      </Router>
    </SidebarProvider>
  );
}

export default App;
