



import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Authorized() {
  const sliderSettings = {
    // dots: true,
    infinite: true,
    speed: 30000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    loop: true,
  };

  return (
    <div className="testimonial-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto text-center">
            <div className="testimonial-heading" style={{ paddingTop: '25px' }}>
              <span className="font-16 font-outfit weight-500 lineh-16 color36 d-inline-block consultingspan margin-b20">
                Authorized Partners
              </span>
            </div>
          </div>
        </div>
        <div className="row column">
          <div className="col-lg-12">
            <Slider {...sliderSettings}>
              <div>
                <img src="/assets/images/AuthPartners/Auth_Partners.jpg" alt="Partner 1" style={{ width: '100%', height: 'auto' }} />
              </div>
              <div>
                <img src="/assets/images/AuthPartners/Auth_Partners.jpg" alt="Partner 1" style={{ width: '100%', height: 'auto' }} />
              </div>
              <div>
                <img src="/assets/images/AuthPartners/Auth_Partners.jpg" alt="Partner 1" style={{ width: '100%', height: 'auto' }} />
              </div>
            </Slider>
            <div className="col-lg-8 mx-auto text-center">
            <div className="testimonial-heading" style={{ paddingTop: '25px' }}>
              <span className="font-16 font-outfit weight-500 lineh-16 color36 d-inline-block consultingspan margin-b20">
                Official Partners
              </span>
            </div>
            
          </div>
            <div>
            <img src="/assets/images/AuthPartners/OfiicialPartners1.webp" alt="Partner 1" style={{ width: '100%', height: 'auto' }} />

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Authorized;
